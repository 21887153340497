<template>
  <div class="home_page flex-column bg-white full-width">
    <title>校趣--校园生活O2O全生态链移动互联网平台</title>
    <meta name="format-detection" content="telephone=no,email=no,adress=no" />
    <meta name="keywords" content="校趣,校园外卖,校园快递,校园跑腿,大学生,趣小兔,扫码点餐,网上支付,第三方支付,二手闲置,app支付,pos支付,社团,二维码,聚合支付,PC收银台,扫码支付,抖音代运营,小红书代运营,社群运营,私域," />
    <meta name="description" content="“校趣”聚焦校园市场和学生群体的日常需求，围绕大学生吃穿玩用行出发，是国内成熟的校园生活O2O全生态链移动互联网平台，提供第三方商家“外卖平台+供应链”一站式“线上化”解决方案，打造大学校园私域流量商业闭环，同时拥有原创百万帐号、媒体资源、优质艺人资源、优质网红KOL、大量素人资源，为政府/企业/品牌提供资深调研分析、创意策划、品牌宣传、达人推广、短视频拍摄、帐号代运营等综合性服务。" />
    <div class="home_head flex-column flex">
      <div class="flex-column flex-between">
        <!-- logo -->
        <div class="home_title_img">
          <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/xiaoqukeji.png')" :fit="fit"></el-image>
        </div>
        <div class="flex1  flex ">
          <div class=" home_head_left flex flex-column">
            <div class="mrb90 white_color  size70"> 让你的校园生活充满乐趣</div>
            <div class="">
              <div>
                <el-popover placement="right" title="" width="100" trigger="click">
                  <div class="popover_buttom">
                    <el-button slot="reference" @click="jumpLive()">校趣生活</el-button>
                    <el-button slot="reference" @click="jumpqxt()">趣小兔</el-button>
                  </div>
                  <el-button slot="reference">合作代理</el-button>
                </el-popover>
              </div>
              <div class="mrt30" @click="merchantOpening()"><el-button>商家开店</el-button></div>
            </div>
          </div>
          <!-- 首页轮播图 -->
          <wheel-seeding></wheel-seeding>
        </div>

      </div>
    </div>
    <div class="flex  head_item_box flexcc">
      <div class="head_item flex-column flexcc" v-for="(item,index) in list" :key="index" @mouseleave="aa()" @mouseover='submeau= index'>
        <div>
          <el-image style="width: 96px; height: 144px" :src="item.url" :fit="fit"></el-image>
        </div>
        <div class="mrt20  size16" style="text-align: center;">
          {{  item.counter }}
        </div>
        <div class="flex mrt50">
          <div class="mrr10" :class="index== 0 ? 'mrb10' : 'mrb5'"> <el-button type="primary" round>{{ item.buttomName}}</el-button></div>
          <div v-if="item.buttomName2"> <el-button type="warning" round>{{ item.buttomName2 }}</el-button></div>
        </div>

        <!-- 浮动二维码 -->
        <div class="hover_box flex fullscreen flexcc" v-show="submeau == index">
          <div class="flexcc flex flex-column">
            <div class=" item_active_imgs">
              <el-image style="width: 100%; height: 100%" :src="item.urlActive" :fit="fit"></el-image>
            </div>
            <div class="mrt50 flex">
              <div class="mrr10">
                <el-button round @click="skipClick(item)">{{ item.buttomName }}</el-button>
              </div>
              <el-button round v-if="item.buttomName2" @click="setShop()">{{ item.buttomName2 }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴11 -->
    <div class="home_partner flex-column flexcc mrb50 border-box">
      <el-image style="width:100%; height: 100%" :src="require('@/assets/imgs/ie.jpg')" :fit="fit"></el-image>
    </div>
    <!-- 关于 -->
    <div class="home_introduce  flex-column ">
      <div class="size42 mrb80 flexcc">关于校趣</div>
      <div class="flexcc home_introduce_imgs flex flexcc">
        <div class="introduce_imgs_box"> <el-image style="width:548px; height: 356px" :src="require('@/assets/imgs/xiaoqugongs.png')" :fit="fit"></el-image></div>
        <div class="size18 flex1 introduce_title_box ">
          <div class="mrb40"> 校趣网络科技有限公司同时作为阿里巴巴集团的“本地生活服务商”，是一家专注线下商家运营解决方案的提供商。</div>
          <div>
            致力于帮助商家打造更具个性化的消费场景及更精细的门店运营，实现全渠道、全链条、全生态化经营，构建私域流量池,让生意经营更简单.
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <relation></relation>

    <!-- 底部 -->
    <page-footer :list="footerList"></page-footer>

  </div>
</template>

<script>
import relation from "./relation.vue";
import pageFooter from "@/components/comment/pageFooter/index.vue";
import wheelSeeding from "./wheelSeeding.vue";
export default {
  components: { relation, pageFooter, wheelSeeding },
  props: {},
  data() {
    return {
      fit: "fill",
      submeau: 3,

      footerList: [
        {
          url: require("@/assets/imgs/two1.jpg"),
          name: "校趣生活小程序",
        },
        {
          url: require("@/assets/imgs/one.jpg"),
          name: "趣小兔小程序",
        },
        {
          url: require("@/assets/imgs/three.jpg"),
          name: "校趣优品小程序",
        },
      ],
      list: [
        {
          url: require("@/assets/imgs/xiaoqushenhuo2.png"),
          counter:
            "校趣生活--是由专业团队打造,专为大学生而设的综合性校园020生活服务平台",
          buttomName: "合租代理",
          urlActive: require("@/assets/imgs/two1.jpg"),
          path: "/schoolFunLife/index",
        },
        {
          url: require("@/assets/imgs/quxiaotu.png"),
          counter: "收款更方便，服务千万商家收钱好帮手",
          path: "/cooperativeAgency/index",
          buttomName: "合租代理",
          buttomName2: "商家开店",
          urlActive: require("@/assets/imgs/one.jpg"),
        },
        {
          url: require("@/assets/imgs/xiaoqushenghuo2.png"),
          counter: "做价格厚道的好产品，精品电商平台",
          path: "/shopping/index",
          buttomName: "品牌入驻",
          urlActive: require("@/assets/imgs/three.jpg"),
        },
      ],
    };
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {
    skipClick(item) {
      console.log("item", item.path);
      this.$router.push({
        path: item.path,
        // query: form,
      });
      console.log("跳转传");
    },
    jumpLive() {
      this.$router.push({
        path: "/schoolFunLife/index",
        // query: form,
      });
    },
    setShop() {
      this.$router.push({
        path: "/merchantSettled/index",
        // query: form,
      });
    },
    jumpqxt() {
      this.$router.push({
        path: "/cooperativeAgency/index",
        // query: form,
      });
    },
    merchantOpening() {
      this.$router.push({
        path: "/merchantSettled/index",
        // query: form,
      });
    },
    aa() {
      console.log("触发鼠标离开事件");
      this.submeau = 3;

      //   this.$Api.getUserList({ id: 1, name: "xxx" }).then((res) => {
      //     console.log("res");
      //     res.data.list
      //   });
    },
    //    async dd(){
    //     const {data} = await this.$Api.getUserList()
    //     console.log(data);
    //     this.list  = data.list
    //    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-button--primary {
  background-color: #3370ff;
  border-color: #3370ff;
}
::v-deep .el-button--warning {
  background-color: #ff8f15;
  border-color: #ff8f15;
}
.home_partner {
  margin: 120px 0px;
  width: 100%;
}
.home_introduce {
  width: 100%;
  height: 588px;
  padding: 20px 0px;
  background-color: #f5f9ff;
  .home_introduce_imgs {
    position: relative;
    // width: 50%;
    height: 70%;
    margin-left: 35%;
    width: 889px;
    height: 354px;
    background-color: #ffffff;
    .introduce_imgs_box {
      position: absolute;
      top: -60px;
      left: -300px;
    }
    .introduce_title_box {
      width: 540px;
      padding: 81px 137px 81px 298px;
      // background-color: blue;
    }
  }
}

.home_head {
  width: 100%;
  background-image: url(../../assets/imgs/home_background_image.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .home_title_img {
    width: 150px;
    height: 34px;
    margin-left: 42px;
    margin-top: 40px;
  }
  .home_head_left {
    margin-left: 15%;
    margin-top: 10%;
  }
  .home_head_img {
    margin-top: 16px;
    width: 379px;
    height: 666px;
  }
}
.item_active_imgs {
  width: 60%;
  height: 60%;

  // width: 75%;
  // height: 239px;
}
.popover_buttom {
  ::v-deep .el-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    border: none;
  }
}
::v-deep .el-popover {
  width: 150px !important;
}
.head_item_box {
  position: relative;
  top: -60px;
  .head_item {
    position: relative;
    //   width: 336px;
    width: 22%;
    height: 421px;
    margin-right: 51px;
    padding: 25px;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
    z-index: 9;
    .hover_box {
      position: absolute;
      top: 0;
      background: rgba(0, 0, 0, 0.6);
      // padding: 40px 25px;
      box-sizing: border-box;
      z-index: 10;
      ::v-deep .el-image__inner {
        border-radius: 31px;
      }
      ::v-deep .el-button {
        border-radius: 25px;
        padding: 13px 23px;
      }
    }
  }
  .head_item:hover {
    cursor: pointer;
    -webkit-transform: translate(0, -20px);
    -moz-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    transition: all 0.3s;
  }
}
</style>