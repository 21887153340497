<template>
  <div>
    <div class="w">
      <el-carousel height="665px">
        <el-carousel-item v-for="(item, index) in urlList" :key="index">
          <h3 class="small">
            <img :src="item.url" alt />
          </h3>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      urlList: [
        {
          url: require("@/assets/imgs/homepage_image1.png"),
        },
        { url: require("@/assets/imgs/homepage_image2.png") },
      ],
    };
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {},
};
</script>
<style lang='scss' scoped>
// ::v-deep.el-carousel__item[data-v-3d282d7c]:nth-child(2n) {
//   background-color: transparent !important;
// }

// ::v-deep .el-carousel__item[data-v-3d282d7c]:nth-child(2n + 1) {
//   background-color: transparent !important;
// }
.w {
  margin: 0 auto;
  width: 417px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 1;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: transparent;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: transparent;
}

.small img {
  width: 100%;
  // height: 100%;
  // width: 656px;
}

.el-carousel__container {
  width: 1226px;
  height: 460px;
}
</style>