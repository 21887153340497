<template>
  <!-- 主页联系我们 -->
  <div class="home_contact_us flex-column flexcc">
    <div class=" size40 mrb40">
      联系我们
    </div>

    <div class="home_contact_imgs flex flexcc">
      <div style="width: 23%;height: 198px;">
        <el-popover placement="bottom" title="" width="200" trigger="hover">
          <div class="flexcc">
            <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/xqsh_code.jpg')" :fit="fit"></el-image>
          </div>
          <div class="size14 flexcc">
            扫一扫添加专员微信
          </div>
          <div class="flex mrr20" slot="reference" style="width: 100%;height: 100%;">
            <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/relation1.png')" :fit="fit"></el-image>
          </div>
        </el-popover>
      </div>

      <div style="width: 23%;height: 198px;">
        <el-popover placement="bottom" title="" width="400" trigger="hover">
          <div class="align-center flex-column flexcc">
            <div class="size20  bold mrb10">预约回电</div>
            <div class="size14  mrb10">专员将在30分钟内向您致电</div>
            <el-form :inline="true" :model="formInline" class="demo-form-inline" :rules="loginRules" ref="loginForm">
              <el-form-item label="" prop="mobile">
                <el-input v-model="formInline.mobile" placeholder="请输入您的手机号" style="width: 400px"></el-input>
              </el-form-item>
              <el-form-item v-if="captcha" prop="code" label="验证码">
                <div class="capchat">
                  <el-input class="input" v-model="formInline.code" style="width: 150px; margin-right: 15px" placeholder="请输入验证码" />
                  <div class="img" :style="{ backgroundImage: `url(${captcha})` }" />
                  <el-button type="text" style="font-size: 14px" @click="getCode">换一组</el-button>
                </div>
              </el-form-item>
            </el-form>

            <div class="mrt10 mrb10">
              <el-button type="primary" round style="width: 400px" @click="onSubmit(formInline)"> 立即预约</el-button>
            </div>
          </div>
          <div class="flex mrr20" slot="reference" style="width: 100%;height: 100%;">
            <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/relation3.png')" :fit="fit"></el-image>
          </div>
        </el-popover>
      </div>

      <div class="flex " style="width: 23%;height: 198px;">
        <el-image style="width: 100%; height: 100%" :src="require('@/assets/imgs/relation2.png')" :fit="fit"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      fit: "fill",
      captcha: "",
      formInline: {
        mobile: "",
        type: 0,
      },
      urlList: [
        require("@/assets/imgs/relation1.png"),
        require("@/assets/imgs/relation2.png"),
        require("@/assets/imgs/relation3.png"),
      ],
      loginRules: {
        mobile: [
          {
            required: true,
            trigger: "blur",
            message: "请输入电话号码",
          },
        ],
      },
    };
  },

  created() {
    this.getCode();
  },
  mounted() {},
  computed: {},

  methods: {
    async getCode() {
      const { data } = await this.$Api.getCode();
      console.log("data", data);
      this.captcha = data.img;
      this.formInline.key = data.key;
    },
    async onSubmit(parameter) {
      this.$refs.loginForm.validate((valid) => {});
      // console.log(parameter, "提交的表单");
      const { msg, code } = await this.$Api.agentAdd(parameter);
      switch (code) {
        case 200:
          this.$message({
            message: "提交成功，工作人员会与你联系",
            type: "success",
          });
          this.formInline = {
            mobile: "",
            type: 0,
          };
          this.getCode();
          break;
        default:
          this.getCode();

          this.$message({
            message: msg,
            type: "error",
          });
      }
    },
  },
};
</script>

<!-- <style >
::v-deep .el-popper {
  top: 2671px !important;
}
::v-deep .el-popper[x-placement^="bottom"] {
  margin-top: 0px !important;
}
</style> -->
<style lang='scss' scoped>
::v-deep .el-button--primary {
  background-color: #3370ff;
  border-color: #3370ff;
}

.home_contact_us {
  width: 100%;
  height: 547px;
  .home_contact_imgs {
    width: 100%;
  }
}
.capchat {
  display: flex;
  .img {
    height: 45px;
    width: 120px;
    background-size: 100% 100%;
    margin-right: 16px;
  }
}
</style>