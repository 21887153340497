import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/schoolFunLife/index',
    name: 'shoolFunLife',
    component: () => import(/* webpackChunkName: "about" */ '../views/schoolFunLife/index.vue')
  },
  {
    path: '/cooperativeAgency/index',
    name: 'cooperativeAgency',
    component: () => import(/* webpackChunkName: "about" */ '../views/cooperativeAgency/index.vue')
  },
  {
    path: '/merchantSettled/index',
    name: 'merchantSettled',
    component: () => import(/* webpackChunkName: "about" */ '../views/merchantSettled/index.vue')
  },
  {
    path: '/shopping/index',
    name: 'shopping',
    component: () => import(/* webpackChunkName: "about" */ '../views/shopping/index.vue')
  },
]

const router = new VueRouter({
  routes,

})
export default router
