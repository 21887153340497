<template>
  <!-- justify-around  -->
  <div class="home_footer flex flexcc ">
    <div class="home_footer_left white_color size14">
      <div><a style="color: aliceblue;" href="https://beian.miit.gov.cn">公网安备： 粤ICP备2021056951号-1  粤 &nbsp </a>| &nbsp开发者：广州校趣科技有限公司 &nbsp|</div>
      <div>
        联系方式：020-3195-7469 &nbsp | &nbsp地址：广州天河车陂路113号东宏国际广场112单元
      </div>
    </div>
    <div class="home_footer_right flex justify-center">
      <div class="mrr30 flex  align-center flex-column " v-for="(item,index) in list" :key="index">
        <div class="home_footer_img mrb10">
          <el-image style="width: 100%; height: 100%" :src="item.url" :fit="fit"></el-image>
        </div>
        <div class="white_color size14">
          {{ item.name }}
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fit: "fill",
    };
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {},
};
</script>
<style lang='scss' scoped>
.home_footer {
  width: 100%;
  height: 191px;
  box-sizing: border-box;
  background-color: #1e2427;
}
.home_footer_img {
  width: 100px;
  height: 100px;
}
.home_footer_left {
  text-align: center;
  line-height: 30px;
  margin-right: 15%;
}
</style>