import {
	Message
} from "element-ui"
import Vue from 'vue'
export class Notify {
	static normal(config) {
		Message({
			duration: 1500,
			offset: 60,
			...config
		})
	}
	static success(config) {
		Notify.normal({
			type: "success",
			...config
		})
	}
	static warning(config) {
		Notify.normal({
			type: "warning",
			...config
		})
	}
	static error(config) {
		Notify.normal({
			type: "error",
			...config
		})
	}
}

Vue.prototype.$notified = Notify
