import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/styles/index.scss"; //scss全局样式文件

let api = require('./api/index')  //将请求方式挂载全局
Vue.prototype.$Api = api

Vue.config.productionTip = false


// 引入elemetn-ui组件库
import ElementUI from 'element-ui';
// 引入element-ui全部css
import 'element-ui/lib/theme-chalk/index.css';
// 使用element
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  // chrome
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
  //to 即将进入的路由
  //from 即将离开的路由
  //next 放行
});